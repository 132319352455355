
import { ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";

export default {
  name: "ForwarderAirWayBillSelect",
  props: {
    forwarder_air_way_bill_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:forwarder_air_way_bill_id", "changeOfferStatus"],

  setup(props) {
    const { t } = useI18n();
    const inputOptions = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isAirWayBillRequired = (value) => {
      if (props.required && !value) {
        return t("airwaybillid");
      }
      return true;
    };

    const {
      errorMessage,
      value: element_id,
      meta,
    } = useField("forwarder_air_way_bill_id", isAirWayBillRequired);

    const getElements = () => {
      ApiService.get("/api/forwarder/voyage/air_way_bill").then(({ data }) => {
        inputOptions.value.list = data.forwarder_air_way_bills;
        inputOptions.value.options = data.forwarder_air_way_bills;
      });
    };
    const selectElements = (query) => {
      if (query !== "") {
        inputOptions.value.loading = true;
        setTimeout(() => {
          inputOptions.value.loading = false;
          inputOptions.value.options = inputOptions.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputOptions.value.options.length) {
            ApiService.query(`/api/forwarder/voyage/air_way_bill`, {
              params: { per_page: 25, number: query },
            }).then(({ data }) => {
              inputOptions.value.list = data.forwarder_air_way_bills;
              inputOptions.value.options = data.forwarder_air_way_bills;
            });
          }
        }, 200);
      } else {
        inputOptions.value.options = [];
      }
    };

    watch(
      () => props.forwarder_air_way_bill_id,
      (first) => {
        element_id.value = first;
      }
    );

    setTimeout(() => {
      getElements();
    }, 3000);

    return {
      element_id,
      inputOptions,
      errorMessage,
      meta,
      getElements,
      selectElements,
    };
  },
};
